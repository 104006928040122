@mixin media-viewer-common() {
    .rpc-mv-dialog {
        .mat-dialog-container {
            padding: 0;
            border-radius: 0;
        }
    }
    .rpc-media-viewer {
        &-body {
            .rpc-media-viewer-body-item {
                &-inner {
                    .default-image-wrap {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .main-image {
                            max-width: 100%;
                            max-height: 100%;
                            object-fit: contain;
                            border-radius: 8px;
                        }
                        .default-image-backdrop {
                            opacity: .4;
                        }
                    }
                    .rpc-video-placeholder {
                        border-radius: 8px;
                    }
                }
            }
        }
        &-footer {
            .rpc-media-viewer-footer {
                &-item {
                    &-block {
                        &.rpc-media-viewer--portrait {
                            .default-image-wrap {
                                .main-background-image {
                                    background-size: contain;
                                }
                            }
                        }
                        .broken-background-image,
                        .main-background-image {
                            border-radius: 4px;
                        }
                        .rpc-video-placeholder {
                            @media screen and (max-height: 600px) and (orientation: landscape) {
                                grid-template-areas: "icon";
                            }
                            &-text {
                                @media screen and (max-height: 600px) and (orientation: landscape) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
